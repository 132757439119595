
.ancho{
  min-width: 100px;
  max-width: 800px;
  height: 600px;
  margin: 30px auto 20px auto;
}
.alto{
  margin-top: auto;
}
.anchocuadro{
  min-width: 150px;
  max-width: 400px;
}
