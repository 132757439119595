.anchocuadro6{
  min-width: 350px;
  max-width: 450px;
  min-height: 550px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Aplicamos un filtro de desenfoque al fondo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.warning {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 3;
  width: 350px;
}
.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px; /* Puedes ajustar el alto del carril aquí */
  border-radius: 5px;
}

.custom-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px; /* Tamaño de la manija */
  height: 25px; /* Tamaño de la manija */
  border-radius: 50%;
  background: #3497F1;
  cursor: pointer;
}

.custom-slider-thumb.animate-thumb {
  animation: pulse 1s ease-out;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

/* Estilos para Chrome, Safari, y Edge */
.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px; /* Tamaño de la manija */
  height: 25px; /* Tamaño de la manija */
  border-radius: 50%;
  background: #3497F1;
  cursor: pointer;
}

/* Estilos para Firefox */
.custom-slider::-moz-range-thumb {
  width: 25px; /* Tamaño de la manija */
  height: 25px; /* Tamaño de la manija */
  border-radius: 50%;
  background: #3497F1;
  cursor: pointer;
}