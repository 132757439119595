
.ancho{
  min-width: 100px;
  max-width: 900px;
  height: 500px;
  margin: 0px auto 0px auto;
}
.alto{
  margin-top: auto;
}
.bg-movil{
  background: white;
  height: 450px;
  max-width: 280px;
  margin: 10px auto 10px auto;
  
}
.bg-movil:hover{
  background:#3497F1;
  height: 500px;
  max-width: 290px;
  color:aliceblue;
  font-weight: bold;
  transition-duration: 0.5s;

}
.modal-overlay {
 filter: blur(2px);
 pointer-events: none; /* No permite interacción con el fondo */
}

