.img-fluid {
  max-width: 100%;
  height: auto;
}
.ancho{
  min-width: 100px;
  max-width: 800px;
  height: 600px;
  margin: 0px auto 0px auto;
}
.alto{
  margin-top: auto;
}