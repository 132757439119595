:root{
  --bs-body-font-family: 'LemonMilk', Helvetica;
}

body {
    background-color: #FFFF;
    
  }
  .container { max-width: 900px; }

h1{
  font-size: calc(1.7rem + 1.5vw);
  color:#2C3F81;
}
.logoclass{
  position: relative;
}
.imgWhats{
  display: inline-block; /* Hace que la etiqueta <a> se comporte como un bloque en línea */
  width: 20px; /* Ancho de la imagen */
  height: 20px; /* Altura de la imagen */
  background-image: url('./../public/images/WhatsAppWeb.png'); /* Especifica la ruta de la imagen */
  background-size: cover; 
  color: #f1f1f1;
}

.mainclass {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  transform: translateY(-100px);
}

.footer {
  position: fixed; /* Fija el elemento en la ventana del navegador */
  bottom: 0; /* Lo coloca en la parte inferior */
  width: 100%; /* Ocupa todo el ancho de la ventana */
  background-color: #f1f1f1; /* Color de fondo */
  text-align: center; /* Centra el texto */
  padding: 5px 0; /* Espaciado interno */
  font: 'LemonMilk';
}

/* Mobile styles */
@media (max-width: 800px) {
  .mainclass {
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }
  .footer {
    position: fixed; /* Fija el elemento en la ventana del navegador */
    bottom: 0; /* Lo coloca en la parte inferior */
    width: 100%; /* Ocupa todo el ancho de la ventana */
    background-color: #f1f1f1; /* Color de fondo */
    text-align: center; /* Centra el texto */
    padding: 5px 0; /* Espaciado interno */
    font: 'LemonMilk';
  }
}