.container11{
  max-width: 640px;
  margin: auto;
}

.camera-container {
  position: relative;
}

.camera-guides {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px dashed white;
  border-radius: 5%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
  @media (max-width: 767px) { /* Typically, 768px is the breakpoint for tablets and mobile devices */
    .video-fullwidth {
      width: 100%; /* Full width on mobile devices */
      height: auto; /* Adjust height automatically */
      object-fit: cover; /* Cover the entire area of the video element */
    }
  }