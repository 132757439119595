.ancho{
    min-width: 100px;
    max-width: 800px;
    height: 600px;
    margin: 30px auto 20px auto;
  }
  .alto{
    margin-top: auto;
  }
  .btn-toolbar{
    display: inline !important;
  }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}