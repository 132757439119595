.floating-camera-button {
  position: fixed; /* O 'absolute' si prefieres que se desplace con el scroll */
  bottom: 20px; /* Distancia desde el fondo de la pantalla */
  right: 40%; /* Distancia desde el lado derecho de la pantalla */
  z-index: 1000; /* Asegura que se mantenga sobre otros elementos */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparente */
  border: none; /* Sin borde */
  border-radius: 100%; /* Forma redonda */
  color: white; /* Color del ícono o texto */
  font-size: 40px; /* Tamaño del ícono o texto */
  cursor: pointer; /* Cambiar el cursor al pasar el mouse */
}
.container9{
  max-width: 640px;
  margin: auto;
}

.camera-container {
  position: relative;
}

.camera-guides {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px dashed white;
  border-radius: 5%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
  @media (max-width: 767px) { /* Typically, 768px is the breakpoint for tablets and mobile devices */
    .video-fullwidth {
      width: 100%; /* Full width on mobile devices */
      height: auto; /* Adjust height automatically */
      object-fit: cover; /* Cover the entire area of the video element */
    }
  }