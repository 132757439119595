.anchos{
    min-width: 200px;
    max-width: 800px;
    padding: 30px;
     background-color: white;
    margin: 0px auto;

  }
.ancho-img{
    min-width: 100px;
    max-width: 350px;
    margin: 0px auto 0px auto;
    border-radius: 20%;
}
label {
    font-size: small;
}

.input-container {
    position: relative;
    
  }
  
  .floating-label {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 2px 6px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
      transition: opacity 2s ease-in-out;
    pointer-events: none;
  }
  
 
  .alert {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    z-index: 1000; /* Asegura que el alerta esté sobre otros contenidos */
    border-radius: 5px;
    opacity: 1; /* Inicia invisible */
    /* Inicia sin tomar espacio */
    transition: opacity 0.5s ease, visibility 0.5s ease; /* Animación suave */
  }
  h6 {
    text-align: center;
    padding: 15px;
  }