.centered{
  min-height: 50vh;
}
.alto{
  margin-top: auto;
}
@media (max-width: 576px) {
  .w-sm-25 {
      width: 50% !important;
  }
}