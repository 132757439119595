
.wrapper {
    width: 420px;
    /*background: rgb(11, 11, 116);*/
    background-image: url("./1.png");
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(100px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
    text-align: center;
    position: absolute;
}
/*.anchos{
    background-image: url("./1.png");
    background-size: cover;
}*/
/*.anchos2{
    background-image: url("./2.png");
    background-size: cover;
}*/
.form-check-input {
    width: 30px; /* Ajusta el ancho según tus necesidades */
    height: 30px; /* Ajusta la altura según tus necesidades */
    outline: 1px solid black;
}
.form-check-label{
    font-size: 30px;
    color: #2C3F81;
    /*padding: 10px;*/
}
.image{
    /*width:"50%"; 
    height:"50%";*/
    max-width: 100%; /* Garantiza que la imagen no exceda el ancho del contenedor padre */
    height: auto;
    background-color: transparent;
    border-radius: 10%;
    /*mix-blend-mode:multiply;*/
}
@media (max-width: 768px) {
    .image {
      width: 30%; /* Reducir el ancho de la imagen en dispositivos más pequeños */
    }
    .form-check-input {
        width: 20px; /* Ajusta el ancho según tus necesidades */
        height: 20px; /* Ajusta la altura según tus necesidades */
    }
    .form-check-label{
        font-size: 20px;
        text-align: left;
        /*padding: 10px;*/
    }
    .anchos{
        text-align: left;
    }
  }